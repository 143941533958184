<template>
	<sf-label :label="$t('functionKeys.config.module.title')" />
	<sf-list relaxed>
		<sf-list-item v-for="option in moduleOptions" :key="option.id">
			<sf-checkbox-input v-model="option.checked"
				:label="option.name"
				:name="option.id">
			</sf-checkbox-input>
		</sf-list-item>
	</sf-list>

	<sf-form ref="configForm" v-model="currentConfig" name="moduleConfig">
		<name-input
			ref="nameInput"
			autofocus
			:default-name="$t('functionKeys.config.module.defaultName')"
			:name-extension="selectedModulesNames">
		</name-input>
	</sf-form>

	<sf-confirm ref="confirmElement" @confirm="confirm" @abort="emits('abort')" />
</template>

<script lang="ts" setup>
	import { computed, ref, watch } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfConfirm, SfForm, useI18n } from '@starface/starface-ui'
	import { FunctionKeyConfig } from '../types'
	import { useFunctionKeyStore } from '../function-key-store'
	import NameInput from './components/input-name.vue'

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, functionKeys, modules } = storeToRefs(functionKeyStore)

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{ (e: 'success', config: FunctionKeyConfig): void, (e: 'abort'): void }>()

	const defaultConfig = {
		name: '',
		activateModuleIds: []
	}

	const currentConfig = ref({ ...(functionKey.value(props.id)?.config ?? defaultConfig) })

	const moduleOptions = ref(modules.value.map(module => ({
		id: module.moduleId,
		checked: !!currentConfig.value.activateModuleIds?.find(gId => gId === module.moduleId),
		name: module.name
	})))

	const configForm = ref<InstanceType<typeof SfForm>>()
	const nameInput = ref<InstanceType<typeof NameInput>>()
	const confirmElement = ref<InstanceType<typeof SfConfirm>>()

	const selectedModules = computed(() => moduleOptions.value.filter(option => option.checked))
	const selectedModulesNames = computed(() => selectedModules.value.map(module => module.name).join(', '))

	watch(() => selectedModules.value, () => confirmElement.value?.clearMessage())

	const invalidConfig = () => {
		return !(currentConfig.value.activateModuleIds) || currentConfig.value.activateModuleIds?.length === 0
	}

	const configExists = (): boolean => {
		const current = currentConfig.value.activateModuleIds?.sort()

		return !!functionKeys.value.filter(fk => {
			const activateModuleIds = fk.config?.activateModuleIds
			return (fk.uuid !== props.id) && activateModuleIds && (activateModuleIds.length > 0)
		}).map(fk => fk.config?.activateModuleIds?.sort())
			.find(fk => JSON.stringify(fk) === JSON.stringify(current))
	}

	const confirm = () => {
		confirmElement.value?.clearMessage()

		if (configForm.value?.validate()) {
			currentConfig.value.activateModuleIds = selectedModules.value.map(module => module.id)

			if (invalidConfig()) {
				confirmElement.value?.showErrorMessage(useI18n().t('functionKeys.config.module.error'))
			} else if (configExists()) {
				confirmElement.value?.showErrorMessage(useI18n().t('functionKeys.config.module.exists'))
			} else {
				if (!currentConfig.value.name) {
					currentConfig.value.name = nameInput.value.fallback
				}

				emits('success', currentConfig.value as FunctionKeyConfig)
			}
		}
	}
</script>
