import RestClient from './RestClient'

export type RedirectMailboxDestination = {
	mailboxId: string
	redirectDestinationType: 'MAILBOX'
}

export type RedirectPhoneNumberDestination = {
	phoneNumber: string
	redirectDestinationType: 'PHONENUMBER'
}

export type  RedirectDestination = RedirectMailboxDestination | RedirectPhoneNumberDestination

export type RedirectTrigger = {
	redirectTriggerType: 'ALWAYS' | 'BUSY' | 'TIMEOUT'
	timeout?: number
}

export type Redirection = {
	enabled: boolean
	groupNumber?: boolean
	id: string
	lastMailboxDestination?: RedirectMailboxDestination
	lastPhoneNumberDestination?: RedirectPhoneNumberDestination
	phoneNumber?: string;
	redirectDestination: RedirectDestination
	redirectTrigger: RedirectTrigger
}

export default class Redirections {
	rest
	url = '/rest/redirects/'

	constructor() {
		this.rest = new RestClient(this.url)
		return this
	}

	get get(): Promise<Response> {
		return this.rest.response
	}
}
