<template>
	<sf-segment id="main" :loading="loading">
		<grid />
		<library />
	</sf-segment>

	<div v-if="showControlElement" id="confirm">
		<sf-confirm
			:abortButtonText="$t('functionKeys.buttons.abort')"
			:confirmButtonText="$t('functionKeys.buttons.save')"
			:disabled="loading"
			@abort="cancel"
			@confirm="save">
		</sf-confirm>
	</div>

	<abort-dialog ref="abortDialog" @abort="resolveApplication('Discarded')" @confirm="save" />
	<phone-dialog ref="phoneDialog" @resolve="resolveApplication('Saved')" @reject="resolveApplication('Abort')" />
</template>

<script lang="ts" setup>
	import { onMounted, ref, onBeforeMount } from 'vue'
	import { storeToRefs } from 'pinia'
	import FunctionKeySets from '@/services/FunctionKeySets'
	import { useFunctionKeyStore } from './function-key-store'
	import Grid from './function-key-grid.vue'
	import Library from './function-key-library.vue'
	import AbortDialog from './dialogs/abort-dialog.vue'
	import PhoneDialog from './dialogs/phone-dialog.vue'
	import { ResponseError, useErrorOutput } from '@/app/error-output'

	const functionKeyStore = useFunctionKeyStore()
	const { allFunctionKeysWithPosition } = storeToRefs(functionKeyStore)

	const abortDialog: AbortDialog = ref()
	const phoneDialog: PhoneDialog = ref()

	const loading = ref(true)
	const showControlElement = process.env.NODE_ENV === 'development'

	let resolveApplication: (cause: ApplicationResolveType) => void = (cause: ApplicationResolveType) => Promise.resolve(cause)

	let initFunctionKeys: string | null = null

	const cancel = () => {
		const hasChanges = !!initFunctionKeys && JSON.stringify(allFunctionKeysWithPosition.value) !== initFunctionKeys

		if (hasChanges) {
			abortDialog.value.open()
		} else {
			resolveApplication('Discarded')
		}
	}

	const save = () => {
		functionKeyStore.putFunctionKeys().then(async (response) => {
			try {
				if (response.ok) {
					const phones = await (new FunctionKeySets().getPhones('0'))

					if (phones.length > 0) {
						phoneDialog.value.loadPhones(phones)
					} else {
						resolveApplication('Saved')
					}

					initFunctionKeys = JSON.stringify(allFunctionKeysWithPosition.value)
				} else {
					await Promise.reject(await response.json())
				}
			} catch (error) {
				useErrorOutput().errorMessage(error as ResponseError)
				resolveApplication('Abort')
			}
		})
	}

	onBeforeMount(() => {
		window.SaveAsync = () => new Promise<ApplicationResolveType>(resolve => {
			resolveApplication = (cause: ApplicationResolveType) => resolve(cause)
			save()
		})

		window.CancelAsync = () => new Promise<ApplicationResolveType>(resolve => {
			resolveApplication = (cause: ApplicationResolveType) => resolve(cause)
			cancel()
		})
	})

	onMounted(async () => {
		try {
			await Promise.all([
				functionKeyStore.fetchAccounts(),
				functionKeyStore.fetchForwards(),
				functionKeyStore.fetchSignalNumbers(),
				functionKeyStore.fetchContactTags(),
				functionKeyStore.fetchDefaults(),
				functionKeyStore.fetchFunctionKeys()
			])
		} catch (e) {
			console.error(e)
		} finally {
			loading.value = false
			initFunctionKeys = JSON.stringify(allFunctionKeysWithPosition.value)
		}
	})
</script>

<style lang="less" scoped>
  #confirm {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    & > * {
      margin: 1rem;
    }
  }

  #main {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    & > *:last-child {
      max-width: fit-content;
    }
  }
</style>
