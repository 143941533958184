import RestClient from './RestClient'
import { FunctionKeyConfig, FunctionKeyType } from '@/function-keys/types'

export type FunctionKeySet = {
	id: string
	keyOrder: Array<string>
	name: string
}

export type APIFunctionKey = FunctionKeyConfig & {
	accountId: string,
	functionKeyType: FunctionKeyType,
	position: number
}

export default class FunctionKeySets {
	rest
	url = '/rest/functionkeysets'

	constructor() {
		this.rest = new RestClient(this.url)
		return this
	}

	get get(): Promise<Response> {
		this.rest.url = this.url
		return this.rest.response
	}

	get defaults(): Promise<Response> {
		this.rest.url = this.url + '/edit/defaults'
		return this.rest.response
	}

	getAll = async () => {
		this.rest.url = this.url
		return await this.rest.response
			.then((response) => response.json())
			.then((kees: FunctionKeySet[]) => kees)
	}

	getKeySet = async (setId: string): Promise<APIFunctionKey[]> => {
		this.rest.url = this.url + '/' + setId
		return await this.rest.response
			.then((response) => response.json())
			.then((kees: APIFunctionKey[]) => kees)
	}

	putKeySet = (setId: string, keySet: APIFunctionKey[]) => {
		this.rest.url = this.url + '/' + setId
		return this.rest.put({
			body: keySet as unknown as BodyInit
		})
	}

	getPhones = async (setId: string): Promise<string[]> => {
		this.rest.url = this.url + '/' + setId + '/phones'
		return await this.rest.response
			.then((response) => response.json())
			.then((phones: string[]) => phones)
	}

	putPhone = async (setId: string, phone: string): Promise<Response> => {
		this.rest.url = this.url + '/' + setId + '/phone?phone=' + phone
		return this.rest.put()
	}
}
