<template>
	<sf-form ref="configForm" v-model="currentConfig" name="addressbookConfig">
		<sf-label :label="t(`functionKeys.config.addressbook.display`)" />
		<div class="flex">
			<sf-checkbox-input
				v-model="currentSelection"
				type="radio"
				name="addressbookRequest"
				:label="$t(`functionKeys.config.addressbook.contactlist`)"
				:value="'CONTACTLIST'">
			</sf-checkbox-input>

			<sf-checkbox-input
				v-model="currentSelection"
				type="radio"
				name="addressbookRequest"
				:label="$t(`functionKeys.config.addressbook.contactsearch`)"
				:value="'CONTACTSEARCH'">
			</sf-checkbox-input>
		</div>

		<sf-dropdown
			ref="folderSelect"
			name="addressBookFolderName"
			:label="$t(`functionKeys.config.addressbook.folder`)"
			:data="folderOptions">
		</sf-dropdown>

		<name-input
			ref="nameInput"
			:default-name="t(`functionKeys.config.addressbook.${currentSelection?.toLowerCase()}`)"
			:nameExtension="currentConfig.addressBookFolderName" />
	</sf-form>

	<phone-only-hint />

	<sf-confirm
		ref="confirmElement"
		:confirmButtonText="$t('functionKeys.buttons.add')"
		@confirm="confirm"
		@abort="emits('abort')">
	</sf-confirm>
</template>

<script lang="ts" setup>
	import { computed, onMounted, ref } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfConfirm, SfDropdown, SfForm, useI18n } from '@starface/starface-ui'
	import { AddressbookRequest, FunctionKeyConfig } from '../types'
	import { useFunctionKeyStore } from '../function-key-store'
	import NameInput from './components/input-name.vue'
	import PhoneOnlyHint from './components/phone-only-hint.vue'

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{
		(e: 'success', config: FunctionKeyConfig): void,
		(e: 'abort'): void
	}>()

	const { t } = useI18n()

	const functionKeyStore = useFunctionKeyStore()
	const { addressBookFolderNames, functionKey, functionKeysByType } = storeToRefs(functionKeyStore)

	const confirmElement = ref<InstanceType<typeof SfConfirm>>()
	const configForm = ref<InstanceType<typeof SfForm>>()

	const currentConfig = ref({
		...(functionKey.value(props.id)?.config ?? {
			name: ''
		})
	})

	const currentSelection = ref(currentConfig.value.addressbookRequest)

	const folderSelect = ref<InstanceType<typeof SfDropdown>>()
	const nameInput = ref<InstanceType<typeof NameInput>>()

	const folderOptions = computed(() => {
		let options: {
			[key: string]: string
		} = {}
		addressBookFolderNames.value?.forEach(folder => {
			options[folder] = folder
		})
		return options
	})

	const configExists = () => functionKeysByType.value('ADDRESSBOOK')
		.findIndex(fk => fk.uuid !== props.id
			&& fk.config?.addressbookRequest === currentConfig.value.addressbookRequest
			&& fk.config?.addressBookFolderName === currentConfig.value.addressBookFolderName) >= 0

	const confirm = () => {
		if (configForm.value?.validate()) {
			if (!currentConfig.value.name) {
				currentConfig.value.name = nameInput.value.fallback
			}
			if (configExists()) {
				confirmElement.value?.showErrorMessage(t('functionKeys.config.addressbook.exists'))
			} else {
				emits('success', currentConfig.value as FunctionKeyConfig)
			}
		}
	}

	const setAvailableOptions = () => {
		if (!(currentConfig.value.addressbookRequest)) {
			const maxFolders = addressBookFolderNames.value.length
			const fks = functionKeysByType.value('ADDRESSBOOK')
			const requestOptions: AddressbookRequest[] = ['CONTACTLIST', 'CONTACTSEARCH']

			for (let i = 0; i < requestOptions.length; i++) {
				if (fks.filter(fk => fk.config?.addressbookRequest === requestOptions[i]).length < maxFolders) {
					const foldersByOption = fks.filter(fk => fk.config?.addressbookRequest
						&& fk.config?.addressbookRequest === requestOptions[i]
						&& fk.config?.addressBookFolderName).map(fk => fk.config?.addressBookFolderName)
					const folder = addressBookFolderNames.value.find(name => !(foldersByOption.includes(name)))
					if (folder) {
						currentConfig.value.addressBookFolderName = folder
						currentConfig.value.addressbookRequest = requestOptions[i]
						break
					}
				}
			}
		}
	}

	onMounted(() => {
		setAvailableOptions()
	})
</script>

<style scoped lang="less">
  .flex {
    display: flex;
    gap: 1rem;

    & > * {
      margin-top: unset !important;
    }
  }
</style>
