<template>
	<sf-segment id="library" padded inverted fluid>
		<sf-header :text="$t('functionKeys.library.header')" size="small" />

		<div class="key-collection">
			<sf-label :label="$t('functionKeys.library.favorites')" size="small" />
			<function-key v-for="key in LIBRARY_FAVORITE" v-show="isVisible(key.id)" :id="key.id" :key="key.id"
				:disabled="isDisabled(key.type)" />

			<sf-label :label="$t('functionKeys.library.functions')" size="small" />
			<function-key v-for="key in LIBRARY_FUNCTION" v-show="isVisible(key.id)" :id="key.id" :key="key.id"
				:disabled="isDisabled(key.type)" />

			<sf-label :label="$t('functionKeys.library.phoneOnly')" size="small" />
			<function-key v-for="key in LIBRARY_PHONE_ONLY" v-show="isVisible(key.id)" :id="key.id" :key="key.id"
				:disabled="isDisabled(key.type)" />
		</div>
	</sf-segment>
</template>

<script setup lang="ts">
	import { ALL_TEMPLATES, FunctionKeyType } from './types'
	import FunctionKey from './function-key.vue'
	import { computed, onMounted, ref } from 'vue'
	import { useAppStore } from '@/app/app-store'
	import { useFunctionKeyStore } from './function-key-store'
	import { storeToRefs } from 'pinia'

	const { hasPermissions } = storeToRefs(useAppStore())
	const functionKeyStore = useFunctionKeyStore()
	const {
		addressBookFolderNames,
		functionKey,
		functionKeys,
		functionKeyAccounts,
		groupLogins,
		signalNumbers,
		forwards,
		validateForward
	} = storeToRefs(functionKeyStore)

	const LIBRARY_FAVORITE = ref<Array<{ id: string, type: FunctionKeyType }>>([])
	const LIBRARY_FUNCTION = ref<Array<{ id: string, type: FunctionKeyType }>>([])
	const LIBRARY_PHONE_ONLY = ref<Array<{ id: string, type: FunctionKeyType }>>([])

	const isDisabled = computed(() => (type: FunctionKeyType) => {
		const keysOfType = functionKeys.value.filter(key => key.position > -1 && key.type === type)
		let amountOfKeys = keysOfType.length
		let maxKeysForType

		switch (type) {
		case 'BUSYLAMPFIELD':
			maxKeysForType = functionKeyAccounts.value.length
			break
		case 'SIGNALNUMBER':
			maxKeysForType = signalNumbers.value.length
			break
		case 'GROUPLOGIN':
			if (amountOfKeys) {
				amountOfKeys = keysOfType.filter(key => key.config?.groupIds?.length)
					.map(key => key.config?.groupIds).flat(1).length
			}
			maxKeysForType = maxCombinations(groupLogins.value.length)
			break
		case 'FORWARDNUMBER':
			if (amountOfKeys) {
				amountOfKeys = keysOfType.filter(key => key.config?.redirectNumberIds?.length).length
			}
			maxKeysForType = maxCombinations(forwards.value.filter(fwd => {
				return fwd.redirectTrigger.redirectTriggerType === 'ALWAYS' && validateForward.value(fwd.id)
			}).length)
			break
		case 'ADDRESSBOOK':
			// folders × 2 (number of type AddressbookRequest)
			maxKeysForType = addressBookFolderNames.value.length * 2
			break
		default:
			maxKeysForType = ALL_TEMPLATES.find(template => template.type === type)?.capacity ?? 0
		}
		return amountOfKeys >= maxKeysForType
	})

	const isVisible = computed(() => (id: string) => {
		return hasPermissions.value((functionKey.value(id) as FunctionKey).permissions)
	})

	const maxCombinations = (nOfOptions: number) => {
		const maxCombinations = Math.pow(2, nOfOptions)
		// removing 'combination none'
		return maxCombinations - 1
	}

	onMounted(() => {
		ALL_TEMPLATES.forEach(template => {
			const key = {
				id: functionKeyStore.createFunctionKey(template.type, -1, true),
				type: template.type
			}

			switch (template.library) {
			case 'FAVORITE':
				LIBRARY_FAVORITE.value.push(key)
				break
			case 'FUNCTION':
				LIBRARY_FUNCTION.value.push(key)
				break
			case 'PHONE_ONLY':
				LIBRARY_PHONE_ONLY.value.push(key)
				break
			}
		})
	})
</script>

<style scoped lang="less">
  @import "constants.less";

  #library {
    padding: @mainPadding !important;
  }

  .key-collection {
    display: flex;
    flex-direction: column;
    gap: @gridGap;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 0.5rem;
    margin-right: -0.5rem;
  }
</style>
