<template>
	<sf-form ref="configForm" v-model="currentConfig" name="simpleConfig">
		<name-input ref="nameInput" autofocus
			:default-name="$t(`functionKeys.key.${longToShortType(currentFunctionKey?.type)}`)" />
	</sf-form>

	<sf-confirm @confirm="confirm" @abort="emits('abort')" />
</template>

<script setup lang="ts">
	import { computed, ref } from 'vue'
	import { SfForm } from '@starface/starface-ui'
	import { useFunctionKeyStore } from '../function-key-store'
	import { storeToRefs } from 'pinia'
	import { FunctionKeyConfig, longToShortType } from '../types'
	import NameInput from './components/input-name.vue'

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey } = storeToRefs(functionKeyStore)

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{ (e: 'success', config: FunctionKeyConfig): void, (e: 'abort'): void }>()

	const currentFunctionKey = computed(() => functionKey.value(props.id))

	const defaultConfig = {
		name: ''
	}

	const currentConfig = ref({ ...(functionKey.value(props.id)?.config ?? defaultConfig) })

	const configForm = ref<InstanceType<typeof SfForm>>()
	const nameInput = ref<InstanceType<typeof NameInput>>()

	const confirm = () => {
		if (configForm.value?.validate()) {
			if (!currentConfig.value.name) {
				currentConfig.value.name = nameInput.value.fallback
			}

			emits('success', currentConfig.value)
		}
	}
</script>
