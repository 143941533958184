<template>
	<div v-click-outside="() => showResultToggle = false">
		<!-- slot for search input component -->
		<slot />

		<div ref="resultContainer">
			<sf-segment v-if="showResultList && !disabled" id="results">
				<sf-list relaxed @scroll="onScroll">
					<sf-list-item v-for="result in filteredResults" :key="result.id" @click.capture.stop="onClick(result)">
						<template #left>
							{{ result.name }}
						</template>

						<template #right>
							<sf-button
								small inverted
								icon="phone"
								:text="type !== 'ACCOUNT' ? result.phoneNumber : ''">
							</sf-button>
						</template>
					</sf-list-item>
				</sf-list>
			</sf-segment>
		</div>
	</div>

	<sf-spacer :factor="1" />
</template>

<script lang="ts" setup>
	import { computed, onMounted, ref, watch, provide } from 'vue'
	import { storeToRefs } from 'pinia'
	import { useFunctionKeyStore } from '../../function-key-store'
	import { AccountSearchResult, FunctionKeyConfig } from '../../types'

	type DataType = 'ALL' | 'ACCOUNT' | 'CONTACT'

	const props = withDefaults(defineProps<{
		configs?: FunctionKeyConfig[],
		disabled?: boolean,
		searchTerm: string,
		type?: DataType,
		filter?: Array<AccountSearchResult>
	}>(), {
		disabled: false,
		searchTerm: '',
		type: 'ALL',
		filter: () => []
	})

	const emits = defineEmits(['selected', 'searching'])

	const functionKeyStore = useFunctionKeyStore()
	const { functionKeys, functionKeyAccounts } = storeToRefs(functionKeyStore)

	const resultContainer = ref<HTMLDivElement>()
	const searchResults = ref<Array<AccountSearchResult>>([])

	const currentPage = ref(0)
	const totalPages = ref(1)
	const maxPageReached = computed(() => currentPage.value >= (totalPages.value - 1))

	const showResultToggle = ref(false)
	provide('showResultToggle', showResultToggle)

	const showResultList = computed(() => {
		return props.searchTerm && searchResults.value.length > 0 && showResultToggle.value
	})

	const filteredResults = computed(() => {
		return searchResults.value.filter(result => result.phoneNumber.includes(props.searchTerm) || result.name.toLowerCase().includes(props.searchTerm.toLowerCase()))
	})

	const currentValue = ref<AccountSearchResult | null>(null)

	const onClick = (result: AccountSearchResult) => {
		currentValue.value = result
		showResultToggle.value = false

		emits('selected', result)
	}

	watch(() => props.searchTerm, async (newValue: string, oldValue: string) => {
		if (newValue && !currentValue.value && (!oldValue || !maxPageReached.value || !newValue.includes(oldValue))) {
			searchResults.value = await loadResults(0)
			showResultToggle.value = true
		} else if (!newValue) {
			searchResults.value = []
		}

		currentValue.value = null

		emits('searching', { searchTerm: newValue, searchResult: searchResults.value })
	})

	const loadResults = async (page?: number) => {
		let allResults: Array<AccountSearchResult> = []

		if (props.type !== 'CONTACT') {
			const safeChain = functionKeyAccounts.value?.filter((account) => {
				const displayInformationMatchesSearch = account.displayInformation.toLowerCase().includes(props.searchTerm.toLowerCase())
				const phoneNumberMatchesSearch = account.primaryInternalPhoneNumber.indexOf(props.searchTerm) >= 0

				let configAccountAlreadyExists = false
				let accountAlreadySelected = false

				if (props.type === 'ACCOUNT') {
					accountAlreadySelected = !!props.configs?.find(cfk => cfk.blfAccountId === account.accountId)
					configAccountAlreadyExists = !!functionKeys.value.find(fk => fk.config?.blfAccountId === account.accountId)
				}

				return (displayInformationMatchesSearch || phoneNumberMatchesSearch) && !configAccountAlreadyExists && !accountAlreadySelected
			}).map(account => ({
				id: account.accountId,
				name: account.displayInformation,
				phoneNumber: account.primaryInternalPhoneNumber
			}))

			if (safeChain) {
				allResults.push(...safeChain)
			}
		}

		if (props.type !== 'ACCOUNT') {
			allResults.push(...(await loadContacts(page ?? 0)))
		}

		return allResults
	}

	const loadContacts = async (page: number) => {
		const contactInformation = await functionKeyStore.searchContacts({ page, searchTerms: props.searchTerm })

		totalPages.value = contactInformation.totalPages
		currentPage.value = contactInformation.page

		return contactInformation.contacts
			.map(contact => contact.phoneNumberValues
				.filter(phoneNumber => phoneNumber.length > 0)
				.map(phoneNumber => ({ id: contact.id, name: contact.summaryValues.join(' '), phoneNumber })))
			.flat(1)
	}

	const onScroll = async (e: Event) => {
		const element = e.target as Element
		const hasScrolledToBottom = element.scrollHeight - (element.scrollTop + element.clientHeight) === 0

		if (hasScrolledToBottom && !maxPageReached.value) {
			searchResults.value.push(...(await loadResults(currentPage.value + 1)))
		}
	}

	onMounted(() => {
		const container = resultContainer.value

		if (container) {
			container.style.width = container.clientWidth + 'px'
		}
	})
</script>

<style lang="less" scoped>
  #results {
    padding: 0;
    position: fixed;
    width: inherit;
    max-height: 12rem;
    z-index: 1000;
  }
</style>
