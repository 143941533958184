<template>
	<sf-segment id="grid" fluid>
		<div id="column-selection">
			<sf-header :text="$t('functionKeys.grid.rowSelection')" size="small" />
			<sf-dropdown
				id="column-dropdown"
				v-model="columns"
				:data="ALL_COLUMNS">
			</sf-dropdown>
		</div>

		<div ref="gridContainer"
			:key="renderKey"
			class="grid"
			:style="`--columns: ${columns}; --rows: ${GRID_ROWS};`" @wheel="convertScroll">

			<div v-for="(_, index) in GRID_ROWS * parseInt(columns)" ref="gridCells" :key="index" class="cell">
				<sf-label :label="String(index + 1).padStart(2, '0')" />

				<div v-drop="{ key: 'functionKey', onDrop: (id: string) => createKey(id, index) }"
					class="field" :class="{ occupied: functionKeyOnPosition(index) }">

					<template v-if="functionKeyOnPosition(index)">
						<div v-drop="{ key: 'functionKey', onDrop: (id: string) => createKey(id, index) }" class="betweener" />

						<function-key
							:id="functionKeyOnPosition(index).uuid"
							@edit="openConfig(index)"
							@delete="openDelete(index)">
						</function-key>
					</template>
				</div>
			</div>
		</div>
	</sf-segment>

	<config-dialog ref="configDialog" />
	<delete-dialog ref="deleteDialog" />
</template>

<script setup lang="ts">
	import { ref, watch, nextTick } from 'vue'
	import { useFunctionKeyStore } from './function-key-store'
	import { storeToRefs } from 'pinia'
	import ConfigDialog from './dialogs/function-key-config-dialog.vue'
	import DeleteDialog from './dialogs/function-key-delete-dialog.vue'
	import { GRID_ROWS, GRID_MAX_COLUMNS, GRID_MIN_COLUMNS } from './constants'
	import FunctionKey from './function-key.vue'

	const ALL_COLUMNS = Array.from({ length: GRID_MAX_COLUMNS - 1 }, (_, i) => i + GRID_MIN_COLUMNS)
		.reduce((acc: Record<string, number>, curr) => {
			acc[curr] = curr
			return acc
		}, {})

	const columns = ref('' + GRID_MIN_COLUMNS)

	// key to rerender grid container
	const renderKey = ref(1)

	const gridContainer = ref<HTMLDivElement>()
	const gridCells = ref<Array<HTMLDivElement>>()

	const configDialog = ref<InstanceType<typeof ConfigDialog>>()
	const deleteDialog = ref<InstanceType<typeof DeleteDialog>>()

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, functionKeyOnPosition, functionKeys } = storeToRefs(functionKeyStore)

	const openConfig = (index: number) => {
		const key = functionKeyOnPosition.value(index)

		if (key) {
			configDialog.value.configFunctionKey(key.uuid, key.position)
		}
	}

	const openDelete = (index: number) => {
		const key: FunctionKey = functionKeyOnPosition.value(index)

		if (key) {
			deleteDialog.value.deleteFunctionKey(key.uuid)
		}
	}

	const createKey = (id: string, index: number) => {
		const key: FunctionKey = functionKey.value(id)

		if (!key) {
			return
		}

		if (key.position > -1) {
			const oldKey = functionKeyOnPosition.value(index)

			if (oldKey && oldKey.uuid !== id) {
				functionKeyStore.shiftPositionsFrom(index)
			}

			key.position = index
		} else {
			const templateType = key.type
			const newFunctionKey = functionKeyStore.createFunctionKey(templateType, -1)

			configDialog.value.configFunctionKey(newFunctionKey, index)
		}

		// vue list needs to re render to avoid wrong key states on drag and drop after changing key positions
		renderKey.value++
	}

	watch(() => functionKeys.value, () => {
		const lastKeyPosition = Math.max(...functionKeys.value.map(k => k.position))

		if ((lastKeyPosition >= parseInt(columns.value) * GRID_ROWS) && (lastKeyPosition < GRID_MAX_COLUMNS * GRID_ROWS)) {
			columns.value = '' + (parseInt(columns.value) + 1)
		}
	}, { deep: true })

	const convertScroll = (e: WheelEvent) => {
		e.preventDefault()

		if (gridContainer.value) {
			if (e.deltaY > 0) {
				gridContainer.value?.scrollBy({
					left: anchors[currentAnchor].clientWidth,
					behavior: 'smooth'
				})
			} else if (e.deltaY < 0) {
				let index = currentAnchor - 1 < 0 ? 0 : currentAnchor - 1
				if (gridContainer.value?.scrollLeft + gridContainer.value?.clientWidth === gridContainer.value?.scrollWidth) {
					index++
				}
				anchors[index]?.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}

	let anchors: Array<Element> = []
	let currentAnchor = 0

	const intersectionObserver = new IntersectionObserver(entries => {
		const entry = entries[0]
		const target = entry.target

		if (entry.isIntersecting) {
			if (anchors.indexOf(target) < currentAnchor) {
				currentAnchor = anchors.indexOf(target)
			}

		} else {
			if (anchors.indexOf(target) === currentAnchor) {
				currentAnchor = anchors.indexOf(target) + 1
			}
		}
	})

	watch(() => gridCells.value?.length, async () => {
		// needed because triggered re-rerender during key creation
		await nextTick()

		if (gridCells.value) {
			intersectionObserver?.disconnect()
			anchors = []

			for (let i = 0; i < parseInt(columns.value); i++) {
				anchors.push(gridCells.value[i * GRID_ROWS])
			}

			anchors.forEach(a => intersectionObserver.observe(a))
		}
	})
</script>

<style scoped lang="less">
  @import "constants.less";

  #column-selection {
    display: flex;
    gap: 1rem;

    & > * {
      margin: unset !important;
    }
  }

  #grid {
    overflow: hidden;
    margin: unset;
    padding: @mainPadding !important;
  }

  .grid {
    max-width: 100%;
    padding: 1rem 0;
    margin-bottom: -1rem;
    display: grid;
    grid-template-rows: repeat(var(--rows), @functionKeyHeight);
    grid-template-columns: repeat(var(--columns), calc(@functionKeySize + 2.5rem));
    grid-auto-flow: column;
    gap: @gridGap;
    overflow: auto;
    scroll-snap-type: x mandatory;

    .cell {
      display: flex;
      align-items: center;
      gap: @gridGap;
      scroll-snap-align: start;
    }

    .field {
      position: relative;
      height: @functionKeyHeight;
      width: @functionKeySize;
      margin: unset !important;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(.occupied) {
        border: 1px dotted white;

        &:not(.match) {
          opacity: 0.33;
        }
      }

      .betweener {
        position: absolute;
        width: 100%;
        height: @gridGap;
        top: -@gridGap;
        right: 0;
      }

      :deep(*) {
        .separator {
          font-style: italic;

          &.blank {
            color: @functionKeyBlankColor !important;
          }
        }
      }
    }
  }
</style>
