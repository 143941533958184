<template>
	<sf-modal id="function-key-config" ref="modal" closable @close="cleanup()">
		<sf-header :text="useI18n().t(`functionKeys.key.${longToShortType(currentFunctionKey.type)}`)" />

		<component
			:is="configComponent"
			:id="currentId"
			@abort="closeModal"
			@success="store($event, currentFunctionKey.type)">
		</component>
	</sf-modal>
</template>

<script setup lang="ts">
	import { forEach } from 'lodash'
	import { storeToRefs } from 'pinia'
	import { computed, ref } from 'vue'
	import { SfHeader, SfModal, useI18n } from '@starface/starface-ui'
	import { useFunctionKeyStore } from '../function-key-store'
	import { FunctionKeyConfig, FunctionKeyType, longToShortType } from '../types'
	import { useAppStore } from '@/app/app-store'
	import {
		ConfigSeparator,
		ConfigBlf,
		ConfigSimple,
		ConfigSignalnumber,
		ConfigQuick,
		ConfigGroup,
		ConfigForwardAll,
		ConfigForwardNumber,
		ConfigForwardTarget,
		ConfigDTMF,
		ConfigURL,
		ConfigQuickBasic,
		ConfigCallList,
		ConfigAddressbook,
		ConfigModule
	} from '../masks'
	import { Permission, PERMISSION_ADDRESSBOOK } from '@/services/Permissions'

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, functionKeyOnPosition } = storeToRefs(functionKeyStore)

	const modal = ref<InstanceType<typeof SfModal>>()

	const currentId = ref('')
	const currentPosition = ref(0)
	const currentFunctionKey = computed(() => functionKey.value(currentId.value))

	const configComponent = computed(() => {
		if (!(useAppStore().hasPermissions(currentFunctionKey.value?.permissions as Permission[]))) {
			return ConfigSimple
		}
		switch (currentFunctionKey.value?.type) {
		case 'BUSYLAMPFIELD':
			return ConfigBlf
		case 'QUICKDIAL':
			if (useAppStore().hasPermissions([PERMISSION_ADDRESSBOOK])) {
				return ConfigQuick
			}
			return ConfigQuickBasic
		case 'SEPARATOR':
			return ConfigSeparator
		case 'SIGNALNUMBER':
			return ConfigSignalnumber
		case 'GROUPLOGIN':
			return ConfigGroup
		case 'FORWARD':
			return ConfigForwardAll
		case 'FORWARDNUMBER':
			return ConfigForwardNumber
		case 'FORWARDTOTARGET':
			return ConfigForwardTarget
		case 'ADDRESSBOOK':
			return ConfigAddressbook
		case 'PHONEGENERICURL':
			return ConfigURL
		case 'PHONEDTMF':
			return ConfigDTMF
		case 'PHONECALLLIST':
			return ConfigCallList
		case 'MODULEACTIVATION':
			return ConfigModule
		default:
			return ConfigSimple
		}
	})

	const openModal = () => {
		modal.value?.open()
	}

	const closeModal = () => {
		if (modal.value?.isOpen()) {
			modal.value?.close()
		}
	}

	const cleanup = () => {
		if (currentFunctionKey.value?.position === -1) {
			functionKeyStore.deleteFunctionKey(currentFunctionKey.value.uuid)
		}
	}

	const configFunctionKey = (id: string, position: number) => {
		currentId.value = id
		currentPosition.value = position

		if (currentFunctionKey.value) {
			openModal()
		}
	}

	const storeConfig = (config: FunctionKeyConfig) => {
		const oldKey = functionKeyOnPosition.value(currentPosition.value)

		if (oldKey && oldKey.uuid !== currentId.value) {
			functionKeyStore.shiftPositionsFrom(currentPosition.value)
		}

		if (currentFunctionKey.value) {
			currentFunctionKey.value.config = { ...config }
			currentFunctionKey.value.position = currentPosition.value
		}

		closeModal()
	}

	const storeConfigs = (configs: FunctionKeyConfig[], keyType: FunctionKeyType) => {
		forEach(configs, (c, index, collection) => {
			storeConfig(c)
			if (collection.length > 1 && index < collection.length - 1) {
				currentPosition.value++
				currentId.value = functionKeyStore.createFunctionKey(keyType, currentPosition.value)
			}
		})
	}

	const store = (config: FunctionKeyConfig | Array<FunctionKeyConfig>, keyType: FunctionKeyType) => {
		if (Array.isArray(config)) {
			storeConfigs(config, keyType)
		} else {
			storeConfig(config)
		}
	}

	defineExpose({ configFunctionKey })
</script>
