<template>
	<sf-modal ref="modal" :closable="false">
		<sf-header :text="$t('functionKeys.info.save')" icon="exclamation triangle" size="small" />

		<sf-spacer :factor="1" />
		<sf-confirm
			:abortButtonText="$t('functionKeys.buttons.discard')"
			:confirmButtonText="$t('functionKeys.buttons.persist')"
			@abort="close('abort')"
			@confirm="close('confirm')">
		</sf-confirm>
	</sf-modal>
</template>

<script lang="ts" setup>
	import { ref } from 'vue'
	import { SfModal } from '@starface/starface-ui'

	const emits = defineEmits(['abort', 'confirm'])
	const modal = ref<InstanceType<typeof SfModal>>()

	const close = (reason: 'abort' | 'confirm') => {
		modal.value?.close()
		emits(reason)
	}

	const open = () => {
		modal.value?.open()
	}

	defineExpose({ close, open })
</script>
