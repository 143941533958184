import FunctionKeys from '@/function-keys/function-keys.vue'
import { createRouter, createWebHashHistory } from 'vue-router'

const routeRecords = [{
	name: 'function-keys',
	path: '/function-keys',
	query: {
		token: ''
	},
	component: FunctionKeys
}, {
	path: '/',
	redirect: {
		name: 'function-keys'
	}
}]

const router = createRouter({
	history: createWebHashHistory(),
	routes: routeRecords
})

export default router
