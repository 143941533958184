<template>
	<template v-if="appState === 'UNAUTHORIZED'">
		<div class="ui active centered">
			<sf-header icon="info" :text="$t('functionKeys.info.unauthorised')" />
		</div>
	</template>
	<template v-else-if="appState === 'INIT'">
		<div class="ui active centered inline loader" />
	</template>
	<template v-else-if="appState === 'READY'">
		<router-view />
	</template>
	<div v-else>
		<!-- TODO: retry button? (if Client API offers refresh method) -->
	</div>
</template>

<script setup lang="ts">
	import { onMounted } from 'vue'
	import { useRouter } from 'vue-router'
	import { useAppStore } from '@/app/app-store'
	import { storeToRefs } from 'pinia'
	import { PERMISSION_KEYS, PERMISSION_PREFERENCES } from '@/services/Permissions'

	const router = useRouter()

	const appStore = useAppStore()
	const { appState, hasPermissions } = storeToRefs(appStore)

	onMounted(async () => {
		try {
			await router.isReady()
			await appStore.login()
			await appStore.fetchMe()
			await appStore.fetchPermissions()

			appState.value = hasPermissions.value([PERMISSION_PREFERENCES, PERMISSION_KEYS]) ? 'READY' : 'UNAUTHORIZED'
		} catch (e) {
			appState.value = 'ERROR'
		}
	})
</script>
