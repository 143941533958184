<template>
	<sf-label :label="$t('functionKeys.config.group.names')" />
	<sf-list relaxed>
		<sf-list-item v-for="option in groupOptions" :key="option.id">
			<sf-checkbox-input
				v-model="option.checked"
				:label="option.name"
				:name="option.id">
			</sf-checkbox-input>
		</sf-list-item>
	</sf-list>

	<sf-form ref="configForm" v-model="currentConfig" name="groupConfig">
		<name-input
			ref="nameInput"
			autofocus
			:default-name="$t('functionKeys.config.group.name')"
			:name-extension="selectedGroupsNames">
		</name-input>
	</sf-form>

	<sf-confirm ref="confirmElement" @confirm="confirm" @abort="emits('abort')" />
</template>

<script lang="ts" setup>
	import { computed, ref, watch } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfConfirm, SfForm, useI18n } from '@starface/starface-ui'
	import { FunctionKeyConfig } from '../types'
	import { useFunctionKeyStore } from '../function-key-store'
	import NameInput from './components/input-name.vue'

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, functionKeys, groupLogins } = storeToRefs(functionKeyStore)

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{ (e: 'success', config: FunctionKeyConfig): void, (e: 'abort'): void }>()

	const defaultConfig = {
		name: '',
		groupIds: []
	}

	const currentConfig = ref({ ...(functionKey.value(props.id)?.config ?? defaultConfig) })

	const groupOptions = ref(groupLogins.value.map(login => ({
		id: login.groupId,
		checked: !!currentConfig.value.groupIds?.find(gId => gId === login.groupId),
		name: login.groupname
	})))

	const configForm = ref<InstanceType<typeof SfForm>>()
	const nameInput = ref<InstanceType<typeof NameInput>>()
	const confirmElement = ref<InstanceType<typeof SfConfirm>>()

	const selectedGroups = computed(() => groupOptions.value.filter(option => option.checked))
	const selectedGroupsNames = computed(() => selectedGroups.value.map(group => group.name).join(', '))

	watch(() => selectedGroups.value, () => confirmElement.value?.clearMessage())

	const invalidConfig = () => {
		return !(currentConfig.value.groupIds) || currentConfig.value.groupIds?.length === 0
	}

	const configExists = (): boolean => {
		const current = currentConfig.value.groupIds?.sort((a, b) => a - b)

		return !!functionKeys.value.filter(fk => {
			const groupIds = fk.config?.groupIds
			return (fk.uuid !== props.id) && groupIds && (groupIds.length > 0)
		}).map(fk => fk.config?.groupIds?.sort((a, b) => a - b))
			.find(fk => JSON.stringify(fk) === JSON.stringify(current))
	}

	const confirm = () => {
		confirmElement.value?.clearMessage()

		if (configForm.value?.validate()) {
			currentConfig.value.groupIds = selectedGroups.value.map(group => group.id)

			if (invalidConfig()) {
				confirmElement.value?.showErrorMessage(useI18n().t('functionKeys.config.group.error'))
			} else if (configExists()) {
				confirmElement.value?.showErrorMessage(useI18n().t('functionKeys.config.group.exists'))
			} else {
				if (!currentConfig.value.name) {
					currentConfig.value.name = nameInput.value.fallback
				}

				emits('success', currentConfig.value as FunctionKeyConfig)
			}
		}
	}
</script>
