<template>
	<sf-text-input
		ref="input"
		name="name"
		:maxlength="KEY_MAX_LENGTH"
		:label="!witheldLabel ? $t('functionKeys.config.nameLabel') : null"
		:placeholder="fallback"
		:rules="[{
			rule: KEY_REGEX,
			message: $t('functionKeys.config.messages.charsSpecific', [KEY_REGEX_CHARS])
		}]">
		<slot />
	</sf-text-input>
</template>

<script setup lang="ts">
	import { SfTextInput } from '@starface/starface-ui'
	import { KEY_MAX_LENGTH, KEY_REGEX, KEY_REGEX_CHARS } from '@/function-keys/constants'
	import { computed, ref, watch } from 'vue'

	const props = defineProps<{ defaultName: string, witheldLabel?: boolean, nameExtension?: string }>()

	const input = ref<InstanceType<typeof SfTextInput>>()

	const fallback = computed(() => {
		if (props.nameExtension) {
			return `${props.defaultName} [${props.nameExtension}]`
		}
		return props.defaultName
	})

	watch(() => props.nameExtension ?? '', newVal => {
		if (input.value && input.value.getValue()) {
			const regex = /\[(.*?)\]|\[(.*)$/
			const value = input.value.getValue()

			if (regex.test(value)) {
				input.value.setValue(value.replace(regex, `[${newVal}]`))
			} else {
				input.value.setValue(`${value} [${newVal}]`)
			}
		}
	})

	watch(() => props.defaultName ?? '', (newVal, oldVal) => {
		if (input.value && input.value.getValue()) {
			const regex = /(.*) \[.*$/
			const value = input.value.getValue()

			if (regex.test(value)) {
				input.value.setValue(value.replace(oldVal, newVal))
			}
		}
	})

	defineExpose({ fallback })
</script>
