import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from '@/app/app.vue'
import router from '@/app/router'
import components from '@starface/starface-ui'
import '@starface/starface-ui/dist/style.css'
import { Theme } from '@starface/starface-ui/compositions/theme'
import { functionKeyTranslationsDe, functionKeyTranslationsEn, functionKeyTranslationsFr } from './function-keys'

export const options = {
	theme: 'starface-dark' as Theme,
	translations: {
		de: {
			...functionKeyTranslationsDe
		},
		en: {
			...functionKeyTranslationsEn
		},
		fr: {
			...functionKeyTranslationsFr
		}
	}
}

createApp(App).use(createPinia())
	.use(router)
	.use(components, options)
	.mount('#app')
