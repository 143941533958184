import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import {
	Account,
	FunctionKey,
	FunctionKeyState,
	FunctionKeyType,
	GroupLogin,
	ModuleOption,
	PhoneNumberConfig,
	VoicemailBoxOption
} from './types'
import { v4 as uuidv4 } from 'uuid'
import FunctionKeySets from '@/services/FunctionKeySets'
import { merge, sortBy } from 'lodash'
import Accounts from '@/services/Accounts'
import Users from '@/services/Users'
import { useI18n } from '@starface/starface-ui'
import Contacts, { QueryParams, Tag } from '@/services/Contacts'
import Redirections, { Redirection, RedirectMailboxDestination } from '@/services/Redirections'
import { useAppStore } from '@/app/app-store'
import {
	Permission,
	PERMISSION_ADDRESSBOOK,
	PERMISSION_CALLLIST, PERMISSION_KEYS,
	PERMISSION_MODULEKEY,
	PERMISSION_REDIRECTION
} from '@/services/Permissions'

export const useFunctionKeyStore = defineStore('function-keys', () => {
	const addressBookFolderNames = ref(new Array<string>())

	const contactTags = ref(new Array<Tag>())

	const functionKeys = ref(new Array<FunctionKey>())

	const functionKeyAccounts = ref(new Array<Account>())

	const groupLogins = ref(new Array<GroupLogin>())
	const forwards = ref(new Array<Redirection>())
	const voicemailBoxes = ref(new Array<VoicemailBoxOption>())
	const modules = ref(new Array<ModuleOption>())

	const signalNumbers = ref([{
		id: 0,
		name: useI18n().t('functionKeys.config.signalling.withheld')
	}])

	const fetchFunctionKeys = async () => {
		try {
			const keySet = await (new FunctionKeySets()).getKeySet('0')

			keySet.forEach(key => {
				const uuid = createFunctionKey(key.functionKeyType, key.position)
				const newKey = functionKey.value(uuid)
				if (newKey) {
					if (newKey.state !== 'ERROR') {
						newKey.state = 'PROVISIONED'
					}
					newKey.accountId = key.accountId
					newKey.config = {
						name: key.name,
						activateModuleIds: key.activateModuleIds,
						addressBookFolderName: key.addressBookFolderName,
						addressbookRequest: key.addressbookRequest,
						blfAccountId: key.blfAccountId,
						callListRequest: key.callListRequest,
						dtmf: key.dtmf,
						directCallTargetnumber: key.directCallTargetnumber,
						displayNumberId: key.displayNumberId,
						forwardTarget: key.forwardTarget,
						forwardTargetType: key.forwardTargetType,
						forwardType: key.forwardType,
						genericURL: key.genericURL,
						groupIds: key.groupIds,
						poNumber: key.poNumber,
						redirectNumberIds: key.redirectNumberIds
					}
					if (newKey.config.forwardTarget?.length) {
						// getting rid of that stupid 'destination:' prefix:
						newKey.config.forwardTarget = newKey.config.forwardTarget.replace(/\D/g, '')
					}
				}
			})
		} catch (e) {
			console.error(e)
		}
	}

	const fetchAccounts = async () => {
		new Accounts().get.then((response) => response.json())
			.then((accounts) => {
				functionKeyAccounts.value = accounts as Account[]
			})
	}

	const fetchDefaults = async () => {
		await (new FunctionKeySets()).defaults.then((response) => response.json())
			.then((defaults) => {
				if (groupLogins.value) {
					groupLogins.value = defaults.editFunctionKeyGroupLogin.editFunctionKeyGlGroupSettings as GroupLogin[]
				}
				if (voicemailBoxes.value) {
					voicemailBoxes.value = defaults.editFunctionKeyForwardToTarget.availableVoicemailBoxes?.map((vmb: VoicemailBoxOption) => {
						const group = isGroupVmb.value(vmb.id)
						return {
							id: vmb.id,
							name: vmb.name,
							owner: vmb.id === parseInt(useAppStore().myId()),
							group
						}
					})
				}
				if (addressBookFolderNames.value) {
					addressBookFolderNames.value = defaults.editFunctionKeyPhoneContact.folders
				}
				if (modules.value) {
					modules.value = defaults.editFunctionKeyModuleActivation.editFunctionKeyMaModuleSettings as ModuleOption[]
				}
			})
	}

	const isGroupVmb = computed(() => (vmbId: number) => {
		return forwards.value.find(forward => {
			return forward.groupNumber
				&& (parseInt((forward.lastMailboxDestination as RedirectMailboxDestination).mailboxId) === vmbId
					|| (forward.redirectDestination.redirectDestinationType === 'MAILBOX'
					&& parseInt((forward.redirectDestination as RedirectMailboxDestination).mailboxId) === vmbId))
		}) !== undefined
	})

	const fetchForwards = async () => {
		if (!(useAppStore().hasPermissions([PERMISSION_REDIRECTION]))) {
			return
		}
		await (new Redirections()).get.then((response) => response.json())
			.then((redirections) => {
				if (forwards.value) {
					forwards.value = redirections as Redirection[]
				}
			})
	}

	const fetchSignalNumbers = async () => {
		new Users().getPhoneNumberConfig(useAppStore().myId())
			.then((pnc) => {
				const sns = sortBy((pnc as unknown as PhoneNumberConfig).possibleSignalnumbers.map(sn => {
					return {
						id: sn.id,
						name: '+' + sn.nationalPrefix + ' ' + sn.localPrefix + ' ' + sn.number
					}
				}), (sn) => {
					const toCompare = sn.name.replaceAll(/\D/g, '')
					return parseInt(toCompare, 10)
				})
				if (signalNumbers.value) {
					signalNumbers.value.push(...sns)
				}
			})
	}

	const createFunctionKey = (type: FunctionKeyType, position: number, template = false) => {
		const permissions = permissionsByType.value(type)
		let state: FunctionKeyState = 'ERROR'
		if (useAppStore().hasPermissions(permissions)) {
			state = template ? 'TEMPLATE' : 'CREATED'
		}

		const uuid = uuidv4()
		functionKeys.value.push({
			uuid,
			accountId: template ? '-1' : useAppStore().myId(),
			type,
			position,
			state,
			permissions
		})

		return uuid
	}

	const deleteFunctionKey = (id: string) => {
		functionKeys.value = functionKeys.value.filter(key => key.uuid !== id)
	}

	const shiftPositionsFrom = (start: number) => {
		const keyOnStartPosition = functionKeys.value.find(k => k.position === start)
		const keyOnNextPosition = functionKeys.value.find(k => k.position === start + 1)

		if (keyOnNextPosition) {
			shiftPositionsFrom(start + 1)
		}

		if (keyOnStartPosition) {
			keyOnStartPosition.position++
		}
	}

	const contactTagIds = computed(() => {
		if (contactTags.value.length) {
			return contactTags.value.map(tag => tag.id)
		}
		return []
	})

	const signalNumber = computed(() => (id: number) => signalNumbers.value.find(k => k.id === id))

	const functionKey = computed(() => (id: string) => functionKeys.value.find(k => k.uuid === id))

	const functionKeysByType = computed(() => (type: FunctionKeyType) => functionKeys.value.filter(k => k.type === type))

	const functionKeyOnPosition = computed(() => (position: number) => functionKeys.value.find(k => k.position === position))

	const allFunctionKeysWithPosition = computed(() => functionKeys.value.filter(k => k.position >= 0))

	const positionOfFunctionKey = computed(() => (id: string) => functionKeys.value.find(k => k.uuid === id)?.position ?? -1)

	const mailboxName = computed(() => (id: string) => voicemailBoxes.value?.find(vmb => vmb.id === parseInt(id))?.name ?? '')

	const validateForward = computed(() => (id: string): boolean => {
		const forward = forwards.value.find(fwd => fwd.id === id)
		return (forward?.redirectDestination.redirectDestinationType === 'MAILBOX'
				&& forward?.redirectDestination.mailboxId.length > 0)
			|| (forward?.redirectDestination.redirectDestinationType === 'PHONENUMBER'
				&& forward?.redirectDestination.phoneNumber.length > 0)
	})

	const permissionsByType = computed(() => (functionKeyType: FunctionKeyType): Permission[] => {
		const permissions = [PERMISSION_KEYS]
		switch (functionKeyType) {
		case 'ADDRESSBOOK':
			permissions.push(PERMISSION_ADDRESSBOOK)
			break
		case 'FORWARD':
		case 'FORWARDNUMBER':
		case 'FORWARDTOTARGET':
			permissions.push(PERMISSION_REDIRECTION)
			break
		case 'PHONECALLLIST':
			permissions.push(PERMISSION_CALLLIST)
			break
		case 'MODULEACTIVATION':
			permissions.push(PERMISSION_MODULEKEY)
			break
		}
		return permissions
	})

	const putFunctionKeys = () => {
		return new FunctionKeySets().putKeySet('0', functionKeys.value
			.filter(value => value.position >= 0 && value.state !== 'TEMPLATE')
			.map(value => {
				return merge({}, value.config, {
					functionKeyType: value.type,
					accountId: useAppStore().myId() as string,
					position: value.position
				})
			})
		)
	}

	const fetchContactTags = async () => {
		if (!(useAppStore().hasPermissions([PERMISSION_ADDRESSBOOK]))) {
			return
		}
		try {
			const tags = await new Contacts().tags
			contactTags.value.push(...tags)
		} catch (e) {
			console.error(e)
		}
	}

	const searchContacts = async (params: QueryParams) => {
		if (!(useAppStore().hasPermissions([PERMISSION_ADDRESSBOOK]))) {
			return { contacts: [], page: 0, totalPages: 0 }
		}
		try {
			if (contactTagIds.value.length === 1) {
				params.tags = contactTagIds.value[0]
			}

			const contactData = await new Contacts().search(params)

			return {
				contacts: contactData.contacts,
				page: contactData.metadata.page,
				totalPages: contactData.metadata.totalPages
			}
		} catch (e) {
			console.error(e)
		}

		return { contacts: [], page: 0, totalPages: 0 }
	}

	return {
		functionKeys,
		functionKeyAccounts,
		forwards,
		addressBookFolderNames,
		groupLogins,
		modules,
		signalNumber,
		signalNumbers,
		voicemailBoxes,
		fetchForwards,
		fetchFunctionKeys,
		fetchDefaults,
		fetchAccounts,
		fetchSignalNumbers,
		createFunctionKey,
		deleteFunctionKey,
		shiftPositionsFrom,
		putFunctionKeys,
		fetchContactTags,
		searchContacts,
		contactTagIds,
		functionKeysByType,
		functionKey,
		functionKeyOnPosition,
		positionOfFunctionKey,
		allFunctionKeysWithPosition,
		mailboxName,
		validateForward,
		permissionsByType
	}
})
