// GRID
export const GRID_ROWS = 8
export const GRID_MIN_COLUMNS = 2
export const GRID_MAX_COLUMNS = 20

// REGEX, input validation
export const KEY_MAX_LENGTH = 80
export const KEY_REGEX = /^[^|%$;'"“<>‘`]*$/
export const KEY_REGEX_CHARS = '|%$;\'"“<>‘`'
export const KEY_REGEX_CALL_NUMBER = /^[*+#]*([(/-]?\s*\d+\)?\s*)*$/
export const KEY_REGEX_CALL_CODE = /^[*#]{1,2}(\+?\d+[*#]+(\+?\d+)*)+$/
export const KEY_REGEX_DTMF = /^[#*0-9ABCD]+$/
export const KEY_REGEX_CALL_NUMBER_CODE = /^([*+#]*([(/-]?\s*\d+\)?\s*)*)([*#]{1,2}(\+?\d+[*#]+(\+?\d+)*)+)?$/
