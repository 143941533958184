<template>
	<sf-label :label="$t('functionKeys.config.signalling.display')" />
	<sf-list relaxed>
		<sf-list-item v-for="option in numberOptions" :key="option.id">
			<sf-checkbox-input
				v-model="option.checked"
				:label="option.name"
				:disabled="isOptionDisabled(option.id)"
				class="full-width">
			</sf-checkbox-input>
		</sf-list-item>
	</sf-list>

	<template v-if="currentConfigs.length">
		<sf-separator />
		<sf-spacer :factor="0.5" />

		<sf-segment basic scrollable>
			<template v-for="(config, index) in currentConfigs" :key="config.displayNumberId">
				<sf-form ref="formElements" v-model="currentConfigs[index]" :name="'form-' + index">
					<name-input
						ref="nameInputs"
						:witheld-label="index > 0"
						:default-name="$t('functionKeys.key.signalling')"
						:name-extension="numberName(config.displayNumberId as number)">

						<sf-button class="delete-button" icon="trash" @click="(config.displayNumberId)" />
					</name-input>
				</sf-form>
			</template>

			<sf-spacer :factor="0" />
		</sf-segment>
	</template>

	<sf-confirm
		ref="confirmElement"
		:confirmButtonText="$t('functionKeys.buttons.add')"
		@confirm="confirm"
		@abort="emits('abort')">
	</sf-confirm>
</template>

<script lang="ts" setup>
	import { computed, ref } from 'vue'
	import { FunctionKeyConfig } from '@/function-keys/types'
	import { useFunctionKeyStore } from '@/function-keys/function-key-store'
	import { storeToRefs } from 'pinia'
	import { SfForm, SfConfirm, SfCheckboxInput, useI18n } from '@starface/starface-ui'
	import NameInput from './components/input-name.vue'

	const props = defineProps<{ id: string }>()
	const emits = defineEmits(['abort', 'success'])

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, signalNumbers, functionKeysByType } = storeToRefs(functionKeyStore)

	const formElements = ref<Array<InstanceType<typeof SfForm>>>()
	const nameInputs = ref<Array<InstanceType<typeof NameInput>>>()
	const confirmElement = ref<InstanceType<typeof SfConfirm>>()

	const currentFunctionKey = computed(() => functionKey.value(props.id))

	const numberOptions = ref(signalNumbers.value.map(number => ({
		id: number.id,
		checked: currentFunctionKey.value?.config?.displayNumberId === number.id,
		name: number.name
	})))

	const numberName = computed(() => (numberId: number) => numberOptions.value.find(option => option.id === numberId)?.name ?? '')

	const isOptionDisabled = computed(() => (option: number) => {
		const configs = [...functionKeysByType.value('SIGNALNUMBER').filter(key => key.uuid !== props.id).map(key => key.config)]
		return !!configs.find(config => config?.displayNumberId === option)
	})

	const currentConfigs = computed<Array<FunctionKeyConfig>>(() => numberOptions.value
		.filter(option => option.checked)
		.map(option => ({
			name: (currentFunctionKey.value?.config?.displayNumberId === option.id) ? currentFunctionKey.value?.config?.name : '',
			displayNumberId: option.id
		}))
	)

	const invalidConfig = () => {
		return currentConfigs.value.find(cfk => cfk.displayNumberId === undefined || (cfk.displayNumberId as number) < 0)
	}

	const validateConfigs = () => {
		return (currentConfigs.value.length && !invalidConfig())
	}

	const confirm = () => {
		const inputValidation = formElements.value?.reduce((acc, form) => {
			if (!acc) {
				return false
			}

			return form.validate()
		}, true)

		if (inputValidation) {
			if (validateConfigs()) {
				const configs = [...currentConfigs.value]

				configs.forEach((config, index) => {
					if (!config.name) {
						config.name = nameInputs.value?.[index].fallback ?? ''
					}
				})

				emits('success', configs)
			} else {
				confirmElement.value?.showErrorMessage(useI18n().t('functionKeys.config.signalling.error'))
			}
		}
	}
</script>

<style scoped lang="less">
  .full-width,
  .full-width:deep(label) {
    width: 100%;
  }

  .delete-button {
    margin-left: 0.5rem;
  }
</style>
