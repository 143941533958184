import RestClient from './RestClient'

export type PermissionInfo = {
	description?: string
	id: number
	parentId?: number
	permission: string
}

export type Permission = {
	id: number
}

export const PERMISSION_REDIRECTION: Permission = { id: 5 }
export const PERMISSION_CALLLIST: Permission = { id: 6 }
export const PERMISSION_ADDRESSBOOK: Permission = { id: 7 }
export const PERMISSION_KEYS: Permission = { id: 9 }
export const PERMISSION_PREFERENCES: Permission = { id: 10 }
export const PERMISSION_GROUP_REDIRECTION: Permission = { id: 24 }
export const PERMISSION_MODULEKEY: Permission = { id: 32 }

export default class Permissions {
	rest
	url = '/rest/permissions/'

	constructor() {
		this.rest = new RestClient(this.url)
	}

	get defaults(): Promise<Response> {
		return this.rest.response
	}

	async getByUser(accountId: number): Promise<Permission[]> {
		this.rest.url = this.url + 'users/' + accountId
		return await this.rest.response
			.then(response => {
				if (response.ok) {
					return response.json()
				}
				return Promise.reject(response.body as unknown as string)
			}).then(perms => {
				return perms.map((perm: PermissionInfo): Permission => {
					return { id: perm.id }
				})
			})
	}
}
