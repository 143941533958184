import RestClient from './RestClient'

export default class Accounts {
	rest
	url = '/rest/accounts/'

	constructor() {
		this.rest = new RestClient(this.url)
	}

	get get(): Promise<Response> {
		return this.rest.response
	}
}
