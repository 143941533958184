<template>
	<sf-label :label="t('functionKeys.config.forwardNumber.title')" />
	<sf-list relaxed>
		<sf-list-item v-for="option in forwardOptions" :key="option.id">
			<template #left>
				<sf-checkbox-input
					v-if="option.destination.length"
					v-model="option.checked">
				</sf-checkbox-input>

				<i v-else class="primary exclamation triangle icon" :title="t('functionKeys.config.forwardNumber.warning')" />

				<i :class="[option.icon, 'icon']" :aria-label="option.icon"></i>
				<span class="ui text">{{ option.name }}</span>
			</template>
			<template #right>
				<div v-if="option.destination.length" class="left floating">
					<i class="long arrow alternate right icon" />
					<span class="ui text">{{ option.destination }}</span>
				</div>
			</template>
		</sf-list-item>
	</sf-list>

	<sf-form ref="configForm" v-model="currentConfig" name="forwardConfig">
		<name-input
			ref="nameInput"
			:default-name="t('functionKeys.config.forwardNumber.defaultName')"
			:name-extension="keyExtension">
		</name-input>
	</sf-form>

	<sf-confirm ref="confirmElement" @confirm="confirm" @abort="emits('abort')" />
</template>

<script lang="ts" setup>
	import { computed, ref, watch } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfConfirm, SfForm, useI18n } from '@starface/starface-ui'
	import { useFunctionKeyStore } from '../function-key-store'
	import { FunctionKeyConfig } from '../types'
	import NameInput from './components/input-name.vue'

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, functionKeys, forwards, mailboxName } = storeToRefs(functionKeyStore)
	const { t } = useI18n()

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{ (e: 'success', config: FunctionKeyConfig): void, (e: 'abort'): void }>()

	const configForm = ref<InstanceType<typeof SfForm>>()
	const confirmElement = ref<InstanceType<typeof SfConfirm>>()
	const nameInput = ref<InstanceType<typeof NameInput>>()

	const defaultConfig = {
		name: '',
		redirectNumberIds: []
	}

	const currentConfig = ref({ ...(functionKey.value(props.id)?.config ?? defaultConfig) })

	const forwardOptions = ref(forwards.value.filter(forward => forward.redirectTrigger.redirectTriggerType === 'ALWAYS').map(forward => {
		let destination
		if (forward.redirectDestination.redirectDestinationType === 'MAILBOX') {
			const boxName = mailboxName.value(forward.redirectDestination.mailboxId)
			destination = boxName || t('functionKeys.config.forwardNumber.vmb', [forward.redirectDestination.mailboxId])
		} else {
			destination = forward.redirectDestination.phoneNumber
		}
		const id = parseInt(forward.id.replace(/\D/g, ''))
		const checked = currentConfig.value.redirectNumberIds
			&& !!currentConfig.value.redirectNumberIds?.find(fid => fid === id)

		return {
			id,
			checked,
			destination,
			icon: forward.groupNumber ? 'users' : 'user',
			name: forward.phoneNumber
		}
	}))

	const selectedForwards = computed(() => forwardOptions.value.filter(option => option.checked))

	const keyExtension = computed(() => {
		const names = selectedForwards.value.map(option => `${option.name} → ${option.destination}`)
		return names.length ? names.join(', ') : t('functionKeys.config.forwardNumber.always')
	})

	watch(() => selectedForwards.value, () => confirmElement.value?.clearMessage())

	const invalidConfig = () => {
		return !(currentConfig.value.redirectNumberIds) || currentConfig.value.redirectNumberIds?.length === 0
	}

	const configExists = (): boolean => {
		const current = currentConfig.value.redirectNumberIds?.sort((a, b) => a - b)

		return !!functionKeys.value.filter(fk => {
			const redirectNumberIds = fk.config?.redirectNumberIds
			return (fk.uuid !== props.id) && redirectNumberIds && (redirectNumberIds.length > 0)
		}).map(fk => fk.config?.redirectNumberIds?.sort((a, b) => a - b))
			.find(fk => JSON.stringify(fk) === JSON.stringify(current))
	}

	const confirm = () => {
		confirmElement.value?.clearMessage()

		if (configForm.value?.validate()) {
			currentConfig.value.redirectNumberIds = selectedForwards.value.map(forward => forward.id)

			if (invalidConfig()) {
				confirmElement.value?.showErrorMessage(t('functionKeys.config.forwardNumber.error'))
			} else if (configExists()) {
				confirmElement.value?.showErrorMessage(t('functionKeys.config.forwardNumber.exists'))
			} else {
				if (!currentConfig.value.name) {
					currentConfig.value.name = nameInput.value.fallback
				}

				emits('success', currentConfig.value as FunctionKeyConfig)
			}
		}
	}
</script>

<style scoped lang="less">
  .left.floating {
    width: 100%;
  }

  .exclamation.triangle.icon {
    margin-top: 1px;
    margin-bottom: 1px;
  }
</style>
